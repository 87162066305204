import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const ChDown = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <path d="M7 10L12 15L17 10H7Z" fill="black"/>
    </SvgIcon>
  );
};

export default ChDown;